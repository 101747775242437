/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';
import PageContent from 'ui/templates/PageContent/PageContent';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import Drawer from 'components/Drawer/Drawer';
import useDrawer from 'hooks/useDrawer/useDrawer';
import NoExternalUserGroups from 'ui/molecules/NoExternalUserGroups/NoExternalUserGroups';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import ArrayUtil from 'utils/Array/Array.util';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import NoExternalUsersWithButton from 'ui/molecules/NoExternalUsersWithButton/NoExternalUsersWithButton';
import Modal from 'components/Modal/Modal';
import {
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant as VisitorGroup
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import useAuth from 'hooks/usaAuth/useAuth';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { getRoleOptionProp } from 'components/Drawer/variants/externalMembers/shared';
import useSortingExternalUsersTable from 'hooks/useSorting/useSortingExternalUsersTable/useSortingExternalUsersTable';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import SearchExternalUsers from 'ui/organisms/SearchExternalUsers/SearchExternalUsers';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Button from 'ui/atoms/Button/Button';
import { GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant as VisitorGroupsWithMembersAndRole } from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { kebabCase } from 'lodash';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import TableCellStatus from 'ui/atoms/TableCellStatus/TableCellStatus';
import { createUsersTabs } from '../usersTabsConst';

dayjs.extend(isBetween);

export const createHeaderGroups = (translation: Translation) => [translation.external_groups];

export const createColumnsUsers = (translation: Translation) => [
  { text: translation.external_users, width: 35 },
  { text: translation.role, width: 15 },
  { text: translation.atlas_access_app, width: 25 },
  { text: translation.expiration_date, width: 25 }
];

const SearchWrapper = styled.div`
  width: 14.875rem;
`;

const ExternalUsers = (): JSX.Element => {
  const { translation } = useTranslation();
  const usersTabs = createUsersTabs(translation);
  const headerGroups = createHeaderGroups(translation);
  const columnsUsers = createColumnsUsers(translation);
  const { showDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    externalUsersGroups,
    loadingWithRole: externalUsersGroupsLoading,
    addVisitorMemberMutationWithRoleLoading,
    updateVisitorMemberByIdWithRoleLoading,
    deleteVisitorGroupByIdMutationLoading
  } = useExternalUsers({
    handleFetchError
  });
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { handleSortingGroupsIcon, handleSortingGroupsOnClick, handleSortingUsersIcon, handleSortingUsersOnClick } =
    useSortingExternalUsersTable(translation);
  const [filterStr, setFilterStr] = useState('');
  const [filterGroupId, setFilterGroupId] = useState<string | undefined>();
  const [filterMemberId, setFilterMemberId] = useState<string | undefined>();
  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
  const { isMobileDeviceRegisteredWithAccess } = useAuth();

  const getGroupById = (groupId: string) => externalUsersGroups.find((group) => group.id === groupId);

  const getGroupByMemberId = (memberId: string) =>
    externalUsersGroups.find((group) => group.members.some((member) => member.id === memberId));

  const getMemberById = (memberId: string) => {
    const groupWithMember = externalUsersGroups.find((group) => group.members.some((member) => member.id === memberId));
    return groupWithMember?.members.find((member) => member.id === memberId);
  };

  const getFilteredGroups = () => {
    if (filterGroupId !== undefined) {
      const filteredGroup = getGroupById(filterGroupId);
      return filteredGroup ? [filteredGroup as VisitorGroupsWithMembersAndRole] : undefined;
    }
    if (filterMemberId !== undefined) {
      const filteredGroup = getGroupByMemberId(filterMemberId);
      return filteredGroup ? [filteredGroup as VisitorGroupsWithMembersAndRole] : undefined;
    }
    return externalUsersGroups as VisitorGroupsWithMembersAndRole[];
  };

  const getFirstSectionItems = () =>
    externalUsersGroups
      .filter((element) => [element].some((key) => key.name.toLowerCase().includes(filterStr.toLowerCase())))
      .map((group) => ({ id: group.id, text: group.name }));

  const getSecondSectionItems = () =>
    externalUsersGroups.flatMap((group) =>
      group.members
        .filter((element) =>
          [element.name, element.email].some((key) => key && key.toLowerCase().includes(filterStr.toLowerCase()))
        )
        .flatMap((member) => ({
          id: member.id,
          text: member.name,
          email: member.email,
          additionalInfoText: group.name
        }))
    );

  const handleFirstSectionRowOnClick = (id: string) => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(id);
    setFilterMemberId(undefined);
    setSelectedGroupId(id);
    const filteredGroups = getGroupById(id);
    if (filteredGroups) setFilterStr(filteredGroups.name);
  };

  const handleSecondSectionRowOnClick = (id: string) => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(undefined);
    setFilterMemberId(id);

    const filteredGroups = getGroupByMemberId(id);
    if (filteredGroups) setSelectedGroupId(filteredGroups.id);
    const filteredMember = getMemberById(id);
    if (filteredMember) setFilterStr(filteredMember.name);
  };

  const handleClearButtonOnClick = () => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(undefined);
    setFilterMemberId(undefined);
    setFilterStr('');
  };

  const handleSetFilterStrOnChange = (text: string) => {
    if (text === '') handleClearButtonOnClick();
    setIsSearchMenuOpen(Boolean(text));
    setFilterStr(text);
  };

  useEffect(() => {
    if (selectedGroupId === undefined && externalUsersGroups.length > 0 && externalUsersGroups[0].id)
      setSelectedGroupId(externalUsersGroups[0].id);
  }, [externalUsersGroups, externalUsersGroupsLoading, selectedGroupId]);

  const handleAddExternalUserGroupClick = () => {
    showDrawer({
      type: 'addExternalUserGroup'
    });
  };

  const handleAddExternalUserClick = (visitorGroup: VisitorGroup) => {
    if (visitorGroup)
      showDrawer({
        type: 'addExternalUser',
        contentValue: { visitorGroupExternalRef: visitorGroup.externalRef }
      });
  };

  const handleEditExternalUserClick = (
    name: string,
    email: string | null,
    mobileDevices: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices[],
    id: string,
    group: string,
    isEditable: boolean,
    expirationDate: string | null,
    role: string | null
  ) => {
    if (
      !externalUsersGroupsLoading &&
      !addVisitorMemberMutationWithRoleLoading &&
      !updateVisitorMemberByIdWithRoleLoading
    )
      showDrawer({
        type: 'editExternalUser',
        contentValue: {
          name,
          email,
          mobileDevices,
          id,
          group,
          isEditable,
          expirationDate,
          role
        }
      });
  };

  const handleEditExternalUserGroupClick = (
    name: string,
    visitorGroupId: string,
    maxNumberOfMembers: number | null
  ) => {
    showDrawer({
      type: 'editExternalUserGroup',
      contentValue: {
        name,
        visitorGroupId,
        maxNumberOfMembers
      }
    });
  };

  const getSelectedGroup = (): VisitorGroupsWithMembersAndRole | undefined => {
    if (filterMemberId !== undefined) {
      const groupsWithFilterMember = getGroupByMemberId(filterMemberId);
      if (groupsWithFilterMember && selectedGroupId) {
        const selectedGroup = getGroupById(selectedGroupId) as VisitorGroupsWithMembersAndRole;
        if (selectedGroup) {
          return { ...selectedGroup, members: selectedGroup.members.filter((member) => member.id === filterMemberId) };
        }
      }
    }
    return selectedGroupId ? (getGroupById(selectedGroupId) as VisitorGroupsWithMembersAndRole) : undefined;
  };

  const isSelectedGroupHaveMembers = () => {
    const selectedGroup = getSelectedGroup();
    return selectedGroup ? selectedGroup.members.length > 0 : undefined;
  };

  const isSelectedGroupNotHaveMembers = () => {
    const selectedGroup = getSelectedGroup();
    return selectedGroup ? selectedGroup.members.length === 0 : undefined;
  };

  const isTableVisible = () =>
    externalUsersGroupsLoading || (!externalUsersGroupsLoading && externalUsersGroups.length > 0);
  const isTableLoadingVisible = () => externalUsersGroupsLoading;
  const isTableGroupsRowsWithDataVisible = () => !externalUsersGroupsLoading && externalUsersGroups.length > 0;
  const isTableMembersRowsWithDataVisible = () =>
    !externalUsersGroupsLoading &&
    !deleteVisitorGroupByIdMutationLoading &&
    externalUsersGroups.length > 0 &&
    isSelectedGroupHaveMembers();
  const isTableNoGroupsVisible = () =>
    !externalUsersGroupsLoading && !deleteVisitorGroupByIdMutationLoading && externalUsersGroups.length === 0;
  const isTableMembersNoMembersVisible = () =>
    !externalUsersGroupsLoading &&
    !deleteVisitorGroupByIdMutationLoading &&
    externalUsersGroups.length > 0 &&
    isSelectedGroupNotHaveMembers();

  useEffect(() => {
    if (deleteVisitorGroupByIdMutationLoading) setSelectedGroupId(undefined);
  }, [deleteVisitorGroupByIdMutationLoading]);

  const handleColorDate = (expirationDateTo: string | null) => {
    if (expirationDateTo) {
      if (dayjs(expirationDateTo).isBefore(dayjs().add(24, 'h'), 'h')) return 'error';
      if (dayjs(expirationDateTo).isBetween(dayjs(), dayjs().add(1, 'week'), 'day')) return 'warning';
    }
    return 'lTextHigh';
  };

  const isGroupSortingAvailable = filterGroupId === undefined && filterMemberId === undefined;
  const isMemberSortingAvailable = () => {
    if (filterGroupId) {
      const filteredGroup = getGroupById(filterGroupId);
      return Boolean(filteredGroup && filteredGroup.members.length > 1);
    }
    if (filterMemberId) return false;
    return true;
  };

  const getMemberRoleInSelectedGroup = (memberId: string) => {
    const selectedGroup = getSelectedGroup();

    return selectedGroup?.members
      .find((member) => member.id === memberId)
      ?.visitorGroupMembership.find(
        (groupMembership) => groupMembership.visitorGroup.externalRef === selectedGroup.externalRef
      )?.role;
  };

  const getMemberRoleCellText = (memberId: string) => {
    const memberRoleInSelectedGroup = getMemberRoleInSelectedGroup(memberId);
    if (memberRoleInSelectedGroup) {
      const roleOptionProp = getRoleOptionProp(memberRoleInSelectedGroup, translation);
      if (roleOptionProp) return roleOptionProp.label;
    }
    return '-';
  };

  const getBeforeContentText = (
    currentNumberOfMembers: number,
    maximumNumberOfMembers: number | null
  ): string | undefined => {
    if (maximumNumberOfMembers) return `${currentNumberOfMembers}/${maximumNumberOfMembers}`;
    return undefined;
  };

  const getBeforeContentTextColor = (
    currentNumberOfMembers: number,
    maximumNumberOfMembers: number | null
  ): keyof Colors | undefined => {
    if (maximumNumberOfMembers && currentNumberOfMembers === maximumNumberOfMembers) return `error`;
    return undefined;
  };

  const isButtonAddMemberDisabled = () => {
    const selectedGroup = getSelectedGroup();
    return (
      (!isTableVisible() && !selectedGroup) ||
      (selectedGroup && selectedGroup.members.length === selectedGroup.maxNumberOfMembers)
    );
  };

  return (
    <PageContent
      title="ATLAS"
      titleId="location"
      tabs={usersTabs}
      buttonText={translation.add_group}
      buttonId="add-external-group-button"
      buttonOnClick={handleAddExternalUserGroupClick}
      id="external-users-page"
    >
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="space-between" gap="0.5rem" width="100%">
          <SearchWrapper>
            <SearchExternalUsers
              isOpen={isSearchMenuOpen}
              filterStr={filterStr}
              setFilterStr={handleSetFilterStrOnChange}
              firstSectionItems={getFirstSectionItems()}
              secondSectionItems={getSecondSectionItems()}
              firstHeader={translation.external_groups}
              secondHeader={translation.external_users}
              handleFirstSectionRowOnClick={handleFirstSectionRowOnClick}
              handleSecondSectionRowOnClick={handleSecondSectionRowOnClick}
              handleClearButtonOnClick={handleClearButtonOnClick}
            />
          </SearchWrapper>
          <ComponentWrapper width="10.5rem">
            <Button
              onClick={() => handleAddExternalUserClick(getSelectedGroup()!)}
              id="add-external-group-member-button"
              disabled={isButtonAddMemberDisabled()}
            >
              {translation.add_user}
            </Button>
          </ComponentWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <ComponentWrapper height="calc(100vh - 23rem)" flex="0" justifyContent="center" alignItems="center">
        {isTableVisible() && (
          <>
            <ComponentWrapper height="calc(100vh - 23rem)" width="10rem" flex="0 0 30%">
              <Table
                header={
                  <TableHeaderRow>
                    <TableHeader
                      headerText={headerGroups[0]}
                      id={kebabCase(`header-${headerGroups[0]}`)}
                      flex="0 0 100%"
                      iconSorting={isGroupSortingAvailable ? handleSortingGroupsIcon(headerGroups[0]) : undefined}
                      onClick={isGroupSortingAvailable ? () => handleSortingGroupsOnClick(headerGroups[0]) : undefined}
                    />
                  </TableHeaderRow>
                }
              >
                {isTableLoadingVisible() &&
                  skeletonArray.map((_, index) => (
                    <TableRow id={`row-skeleton-${index}`} key={`skeletonTableLeftColumnRow-${_.id}`}>
                      <TableCell isLoading firstLineText="" flex="0 0 100%" />
                    </TableRow>
                  ))}
                {isTableGroupsRowsWithDataVisible() &&
                  getFilteredGroups()?.map((group, id) => (
                    <TableRow
                      onClick={() => setSelectedGroupId(group.id)}
                      id={`row-group-${id}`}
                      hoverEffect
                      hoverAfterIconEffect
                      selected={group.id === selectedGroupId}
                      key={group.id}
                      beforeContentIconProps={{
                        name: 'CoContractor',
                        width: 24,
                        height: 24,
                        viewBox: '0 0 32 32',
                        color: 'transparent',
                        stroke: 'lTextHigh'
                      }}
                      beforeContentText={
                        group.maxNumberOfMembers
                          ? getBeforeContentText(group.members.length, group.maxNumberOfMembers)
                          : undefined
                      }
                      beforeContentTextColor={
                        group.maxNumberOfMembers
                          ? getBeforeContentTextColor(group.members.length, group.maxNumberOfMembers)
                          : undefined
                      }
                      afterContentIconProps={{
                        name: 'EditPen',
                        id: `row-group-${id}-icon-edit-pen`,
                        width: 24,
                        height: 24,
                        onClick: () => handleEditExternalUserGroupClick(group.name, group.id, group.maxNumberOfMembers)
                      }}
                    >
                      <TableCell
                        firstLineText={group.name}
                        firstLineId={`group-row-${id}-name`}
                        firstLineColor={group.id === selectedGroupId ? 'primary' : 'lTextHigh'}
                        isRowWithIconAndSwitch
                        flex="0 0 100%"
                      />
                    </TableRow>
                  ))}
              </Table>
            </ComponentWrapper>
            <ComponentWrapper height="calc(100vh - 23rem)" width="10.5rem" flex="0 0 70%">
              <Table
                header={
                  <TableHeaderRow>
                    <TableHeader
                      headerText={columnsUsers[0].text}
                      id={kebabCase(`header-${columnsUsers[0].text}`)}
                      flex={`0 0 ${columnsUsers[0].width}%`}
                      iconSorting={
                        isMemberSortingAvailable() ? handleSortingUsersIcon(columnsUsers[0].text) : undefined
                      }
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(columnsUsers[0].text) : undefined
                      }
                    />
                    <TableHeader
                      headerText={columnsUsers[1].text}
                      id={kebabCase(`header-${columnsUsers[1].text}`)}
                      flex={`0 0 ${columnsUsers[1].width}%`}
                      iconSorting={
                        isMemberSortingAvailable() ? handleSortingUsersIcon(columnsUsers[1].text) : undefined
                      }
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(columnsUsers[1].text) : undefined
                      }
                    />
                    <TableHeader
                      headerText={columnsUsers[2].text}
                      id={kebabCase(`header-${columnsUsers[2].text}`)}
                      flex={`0 0 ${columnsUsers[2].width}%`}
                      iconSorting={
                        isMemberSortingAvailable() ? handleSortingUsersIcon(columnsUsers[2].text) : undefined
                      }
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(columnsUsers[2].text) : undefined
                      }
                    />
                    <TableHeader
                      headerText={columnsUsers[3].text}
                      id={kebabCase(`header-${columnsUsers[3].text}`)}
                      flex={`0 0 ${columnsUsers[3].width}%`}
                      iconSorting={
                        isMemberSortingAvailable() ? handleSortingUsersIcon(columnsUsers[3].text) : undefined
                      }
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(columnsUsers[3].text) : undefined
                      }
                    />
                  </TableHeaderRow>
                }
              >
                {isTableLoadingVisible() &&
                  skeletonArray.map((_, index) => (
                    <TableRow id={`row-skeleton-${index}`} key={`skeletonTableRightColumnRow-${_.id}`}>
                      <TableCell isLoading firstLineText="" flex={`0 0 ${columnsUsers[0].width}%`} />
                      <TableCell isLoading firstLineText="" flex={`0 0 ${columnsUsers[1].width}%`} />
                      <TableCell isLoading firstLineText="" flex={`0 0 ${columnsUsers[2].width}%`} />
                      <TableCell isLoading firstLineText="" flex={`0 0 ${columnsUsers[3].width}%`} />
                    </TableRow>
                  ))}
                {isTableMembersRowsWithDataVisible() &&
                  getSelectedGroup()?.members.map((member, id) => (
                    <TableRow
                      id={`row-external-group-member-${id}`}
                      hoverEffect
                      onClick={() =>
                        handleEditExternalUserClick(
                          member.name,
                          member.email,
                          member.mobileDevices,
                          member.id,
                          selectedGroupId!,
                          member.isEditable!,
                          member.expirationDate,
                          getMemberRoleInSelectedGroup(member.id) || null
                        )
                      }
                      key={`TableRowMember-${member.id}`}
                    >
                      <TableCell
                        firstLineText={member.name}
                        firstLineId={`row-external-group-member-${id}-name`}
                        secondLineText={member.email || undefined}
                        secondLineId={`row-external-group-member-${id}-email`}
                        flex={`0 0 ${columnsUsers[0].width}%`}
                      />
                      <TableCell
                        beforeContentIconProps={
                          getMemberRoleCellText(member.id) === 'Owner'
                            ? { name: 'Star', width: 16, height: 16 }
                            : undefined
                        }
                        firstLineText={getMemberRoleCellText(member.id)}
                        firstLineId={`row-external-group-member-${id}-role`}
                        flex={`0 0 ${columnsUsers[1].width}%`}
                      />
                      <TableCellStatus
                        status={isMobileDeviceRegisteredWithAccess(member.mobileDevices) ? 'connected' : 'disconnected'}
                        id={`row-external-group-member-${id}-status`}
                        flex={`0 0 ${columnsUsers[2].width}%`}
                      />
                      <TableCell
                        firstLineText={
                          member.expirationDate
                            ? dayjs(member.expirationDate).format('DD-MM-YYYY HH:mm')
                            : `${translation.never_expire}`
                        }
                        firstLineColor={handleColorDate(member.expirationDate)}
                        firstLineId={`row-external-group-member-${id}-expirationDate`}
                        flex={`0 0 ${columnsUsers[3].width}%`}
                      />
                    </TableRow>
                  ))}
                {isTableMembersNoMembersVisible() && (
                  <ComponentWrapper justifyContent="center" alignItems="center" height="calc(100vh - 23rem)">
                    <NoExternalUsersWithButton
                      handleOnButtonClick={() => handleAddExternalUserClick(getSelectedGroup()!)}
                    />
                  </ComponentWrapper>
                )}
              </Table>
            </ComponentWrapper>
          </>
        )}
        {isTableNoGroupsVisible() && <NoExternalUserGroups handleOnButtonClick={handleAddExternalUserGroupClick} />}
      </ComponentWrapper>
      <Drawer />
      <Modal />
    </PageContent>
  );
};
export default ExternalUsers;
