import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import PageContent from 'ui/templates/PageContent/PageContent';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Search from 'ui/molecules/Search/Search';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import useSchedules from 'hooks/useSchedules/useSchedules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import { SchedulesState } from 'state/types';
import ArrayUtil from 'utils/Array/Array.util';
import useSortingSchedulesTable from 'hooks/useSorting/useSortingSchedulesTable/useSortingSchedulesTable';
import NoGroups from 'ui/molecules/NoGroups/NoGroups';
import { GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { kebabCase, uniq } from 'lodash';
import Modal from 'components/Modal/Modal';
import useModal from 'hooks/useModal/useModal';
import getVisitorGroupIcon, { VisitorGroupType } from 'utils/VisitorGroupIcon/VisitorGroupIcon.util';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import dayjs from 'dayjs';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { createAccessTabs } from '../accessTabsConst';

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

const TableWrapper = styled.div`
  height: calc(100vh - 24rem);
  width: calc(100vw - 25rem);
  margin-right: 1rem;
`;

export const getTranslatedDayName = (dayName: string, translation: Translation) => {
  switch (dayName.toLowerCase()) {
    case 'monday':
      return translation.monday;
    case 'tuesday':
      return translation.tuesday;
    case 'wednesday':
      return translation.wednesday;
    case 'thursday':
      return translation.thursday;
    case 'friday':
      return translation.friday;
    case 'saturday':
      return translation.saturday;
    case 'sunday':
      return translation.sunday;

    default:
      return '';
  }
};

export const createHeaders = (translation: Translation) => [translation.name, translation.schedules];

const getScheduleDaysForGroup = (
  group: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant,
  translation: Translation
): string => {
  const days: Array<string> = [];
  group[
    group.defaultAccessGrants.length > group.scheduledAccessGrants.length
      ? 'defaultAccessGrants'
      : 'scheduledAccessGrants'
  ].forEach((grant) => {
    grant.accessSchedule.weekDays.forEach((day) =>
      days.push(getTranslatedDayName(day.dayName.charAt(0).toUpperCase() + day.dayName.slice(1), translation))
    );
  });
  return days.toString();
};

const CONSTANT_DATE = '2000-01-01';
const getTimeFormatWithoutSec = (time?: string) =>
  time ? dayjs(`${CONSTANT_DATE} ${time}`).format('HH:mm') : undefined;

const getScheduleTimeForGroup = (group: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant): string => {
  const days: Array<string> = [];
  group[
    group.defaultAccessGrants.length > group.scheduledAccessGrants.length
      ? 'defaultAccessGrants'
      : 'scheduledAccessGrants'
  ].forEach((grant) => {
    grant.accessSchedule.weekDays.forEach((day) =>
      days.push(`${getTimeFormatWithoutSec(day.from)} - ${getTimeFormatWithoutSec(day.to)}`)
    );
  });
  return uniq(days).length > 1 ? 'Mixed' : days[0];
};

const SchedulesPage = (): JSX.Element => {
  const { translation } = useTranslation();
  const headers = createHeaders(translation);
  const accessTabs = createAccessTabs(translation);
  const [filterStr, setFilterStr] = useState('');
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { schedules, loading } = useSchedules({ handleFetchError });
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { handleSortingOnClick, handleSortingIcon } = useSortingSchedulesTable(translation);
  const { showModal } = useModal();

  const getFilterData = useCallback((): SchedulesState => {
    if (filterStr !== '')
      return schedules.filter((element) =>
        [
          element.name,
          getScheduleDaysForGroup(element, translation) || '',
          getScheduleTimeForGroup(element) || ''
        ].some((key) => key.toLowerCase().includes(filterStr.toLowerCase()))
      );
    return schedules;
  }, [filterStr, schedules, translation]);

  const handleRowClick = useCallback(
    (group: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant) => {
      showModal({
        type: 'editSchedule',
        contentValue: group
      });
    },
    [showModal]
  );

  const schedulesTableRows = useMemo(
    () =>
      getFilterData().map((group, id) => (
        <TableRow
          onClick={() => handleRowClick(group)}
          id={`row-${id}`}
          hoverEffect
          hoverArrow
          key={group.id}
          beforeContentIconProps={{
            name: getVisitorGroupIcon.getVisitorGroupIcon(group.type as VisitorGroupType),
            width: 24,
            height: 24,
            viewBox: '0 0 32 32',
            color: 'transparent',
            stroke: 'lTextHigh'
          }}
        >
          <TableCell firstLineText={group.name} firstLineId={`row-${id}-name`} flex="0 0 50%" />
          <TableCell
            firstLineText={getScheduleDaysForGroup(group, translation)}
            secondLineText={getScheduleTimeForGroup(group)}
            firstLineId={`row-${id}-schedules`}
            flex="0 0 50%"
          />
        </TableRow>
      )),
    [getFilterData, handleRowClick, translation]
  );

  return (
    <PageContent title="ATLAS" titleId="location" tabs={accessTabs} id="schedules-page">
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <Search onChange={(event) => setFilterStr(event.target.value)} value={filterStr} />
          </SearchWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <TableWrapper>
        <Table
          header={
            <TableHeaderRow placeForAvatarOrSwitchOrCheckbox placeForArrow>
              <TableHeader
                headerText={headers[0]}
                id={kebabCase(`header-${headers[0]}`)}
                flex="0 0 50%"
                iconSorting={handleSortingIcon(headers[0])}
                onClick={() => handleSortingOnClick(headers[0])}
              />
              <TableHeader headerText={headers[1]} id={kebabCase(`header-${headers[1]}`)} flex="0 0 50%" />
            </TableHeaderRow>
          }
        >
          {loading
            ? skeletonArray.map((_, index) => (
                <TableRow id={`row-skeleton-${index}`} key={`skeletonTableRow-${_.id}`}>
                  <TableCell isLoading firstLineText="" flex="0 0 50%" />
                  <TableCell isLoading firstLineText="" flex="0 0 50%" />
                </TableRow>
              ))
            : schedules.length > 0 && schedulesTableRows.map((row) => row)}
          {!loading && schedules.length === 0 && (
            <ComponentWrapper width="100%" height="calc(100vh - 25rem)" alignItems="center" justifyContent="center">
              <NoGroups />
            </ComponentWrapper>
          )}
        </Table>
      </TableWrapper>
      <Modal />
    </PageContent>
  );
};

export default SchedulesPage;
