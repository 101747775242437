import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Title from 'ui/atoms/Title/Title';
import { Button, Divider, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { lightMuiTheme } from 'theme/theme';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 8rem;
  width: calc(100vw - 16rem);
  max-height: calc(100vh - 15rem);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 5rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.dTextLow};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.dTextMedium};
  }
`;

export interface Props {
  organizationName?: string;
  onStartButtonClick: () => void;
}

const SyncingUsers: React.FC<Props> = ({ organizationName, onStartButtonClick }) => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title />
    </TitleWrapper>
    <Wrapper>
      <Typography variant="h4">Synchronizing users from {organizationName}</Typography>
      <ContentWrapper>
        <Divider />
        <ComponentWrapper flexDirection="column" gap="1.5rem">
          <Typography variant="body1">Synchronization of EntraID users can be done in two different ways:</Typography>
          <ComponentWrapper margin="0 2rem">
            <Typography variant="body1">
              <b>“ATLAS” prefix</b> (Available to all tiers)
              <br /> Prefix the names of the user groups you wish to sync with “ATLAS” (e.g. “ATLAS Employees”). All
              groups with this prefix will be automatically synchronized with ATLAS.
              <br />
              <br />
              <b>ATLAS Sync app</b> (Enterprise tier only)
              <br /> Using the ATLAS Sync Enterprise app to select specific user groups in EntraID that should be synced
              with ATLAS
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" gap="1.5rem">
            <Alert severity="warning" icon={<WarningAmberOutlined sx={{ fill: lightMuiTheme.palette.warning.dark }} />}>
              <Typography variant="body1" style={{ color: lightMuiTheme.palette.warning.dark }}>
                <b>Important:</b> <br />
                ATLAS is role based access, and can only be synced with user groups. Not individual users. users.
              </Typography>
            </Alert>
          </ComponentWrapper>
          <Typography variant="body1">
            To start the synchronization process and link the license to the organization, click on the start-button
            below.
          </Typography>
        </ComponentWrapper>
        <Divider />
      </ContentWrapper>
      <ComponentWrapper alignSelf="center" width="11rem">
        <Button fullWidth variant="contained" size="large" color="primary" onClick={onStartButtonClick}>
          start
        </Button>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default SyncingUsers;
