import React from 'react';
import styled from 'styled-components';
import MaterialDrawer, { DrawerProps } from '@material-ui/core/Drawer/Drawer';
import { useStyles, lightMuiTheme } from 'theme/theme';
import { ThemeProvider } from '@material-ui/core';

interface Props extends DrawerProps {
  open: boolean;
  onClose: () => void;
}

const Content = styled.div`
  padding: 4.25rem 3rem 4.9375rem;
  height: 100%;
  overflow: hidden;
`;

const Drawer: React.FC<Props> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={lightMuiTheme}>
      <MaterialDrawer classes={classes} {...rest} anchor="right">
        <Content>{children}</Content>
      </MaterialDrawer>
    </ThemeProvider>
  );
};
export default Drawer;
