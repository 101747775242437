import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import Icon, { Icons } from '../Icon/Icon';
import Typography from '../Typography/Typography';

export interface Props extends React.RefAttributes<HTMLAnchorElement> {
  location: string;
  text: string;
  icon: Icons;
  isSelected?: boolean;
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 1.75rem 0;
  height: 1.5rem;

  svg {
    margin-right: 1.6875rem;
  }

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const MenuItem: React.FC<Props> = ({ location, isSelected, text, icon, ...props }) => (
  <StyledLink {...props} to={location} id={kebabCase(`menu-item-${location}`)}>
    <Icon name={icon} color={isSelected ? 'primary' : 'lTextHigh'} />
    <Typography variant="body3">{text}</Typography>
  </StyledLink>
);

export default MenuItem;
