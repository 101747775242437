import { gql } from '@apollo/client';

export const GET_CURRENT_HOST = gql`
  query GetCurrentHost {
    getCurrentHost {
      id
      email
      isAdmin
      isAccessByScheduleEnabled
      mobileDevices {
        id
        isRegistered
        registrationToken
        registrationDate
        role
      }
    }
  }
`;

export const GET_OFFICE_DAYS = gql`
  query GetOfficeDays($filter: OfficeDayFilters) {
    getOfficeDays(filter: $filter) {
      date
    }
  }
`;

export const GET_VISITS = gql`
  query GetVisits($filter: VisitsFilter, $sortBy: VisitsSort, $limit: Int, $nextToken: String) {
    getVisits(filter: $filter, sortBy: $sortBy, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateEnd
        dateStart
        location
        source
        host {
          email
          name
        }
        attendees {
          ... on Host {
            name
          }
          ... on Guest {
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_DOORS_FOR_TENANT = gql`
  query GetDoorsForTenant {
    getDoorsForTenant {
      id
      name
      location {
        id
        name
        type
      }
      domSerialNumber
      batteryWarningLevel
    }
  }
`;

export const GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE = gql`
  query GetDoorsForTenantWithOfficeMode {
    getDoorsForTenant {
      id
      name
      location {
        id
        name
        type
      }
      domSerialNumber
      batteryWarningLevel
      officeMode {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_DOORS_FOR_TENANT_WITH_LOCATIONS = gql`
  query GetDoorsForTenantWithLocations {
    getDoorsForTenant {
      id
      name
      externalLocation {
        id
        name
        type
      }
      manualLocations {
        id
        name
        externalRef
        type
      }
      domSerialNumber
      batteryWarningLevel
      officeMode {
        ... on WeekSchedule {
          weekDays {
            dayName
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS_FOR_TENANT = gql`
  query GetLocationsForTenant {
    getLocationsForTenant {
      id
      name
      type
    }
  }
`;

export const GET_LOCATIONS_WITH_DOORS_FOR_TENANT = gql`
  query GetLocationsWithDoorsForTenant {
    getLocationsForTenant {
      id
      name
      externalRef
      doors {
        id
        name
        location {
          id
          name
          type
        }
        domSerialNumber
        batteryWarningLevel
      }
      type
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT = gql`
  query GetVisitorGroupsForTenant {
    getVisitorGroupsForTenant {
      id
      name
      externalRef
      defaultAccessGrants {
        id
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
      }
      scheduledAccessGrants {
        id
        doors {
          id
          name
          location {
            id
            name
            type
          }
          domSerialNumber
          batteryWarningLevel
        }
      }
      type
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS = gql`
  query GetVisitorGroupsForTenantWithMembers($filter: VisitorGroupsFilter) {
    getVisitorGroupsForTenant(filter: $filter) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
      }
      type
      externalRef
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE = gql`
  query GetVisitorGroupsForTenantWithMembersAndRole($filter: VisitorGroupsFilter) {
    getVisitorGroupsForTenant(filter: $filter) {
      id
      name
      members {
        id
        name
        email
        mobileDevices {
          id
          isRegistered
          registrationToken
          registrationDate
          role
        }
        isEditable
        expirationDate
        visitorGroupMembership {
          visitorGroup {
            externalRef
            type
          }
          role
        }
      }
      type
      externalRef
      maxNumberOfMembers
    }
  }
`;
export const GET_ACCESS_EVENTS_FOR_TENANT = gql`
  query GetAccessEventsForTenant($nextToken: String, $filter: AccessEventsFilter) {
    getAccessEventsForTenant(nextToken: $nextToken, filter: $filter) {
      items {
        id
        createdAt
        visitor {
          id
          name
          email
        }
        door {
          id
          name
        }
        action
        reason
      }
      nextToken
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES = gql`
  query GetVisitorGroupsForTenantWithSchedules {
    getVisitorGroupsForTenant {
      id
      name
      defaultAccessGrants {
        id
        accessSchedule {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
      }
      scheduledAccessGrants {
        id
        accessSchedule {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
      }
      type
    }
  }
`;

export const GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP = gql`
  query GetVisitorGroupsForTenantWithIsAdminGroup($filter: VisitorGroupsFilter) {
    getVisitorGroupsForTenant(filter: $filter) {
      id
      name
      externalRef
      isAdminGroup
    }
  }
`;

export const GET_AVAILABLE_OFFICE_DAYS = gql`
  query GetAvailableOfficeDays($from: AWSDate!, $to: AWSDate!) {
    getAvailableOfficeDays(from: $from, to: $to) {
      date
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED = gql`
  query GetVisitorGroupsForTenantWithIsOfficeModeEnabled {
    getVisitorGroupsForTenant {
      id
      name
      defaultAccessGrants {
        id
        isOfficeModeEnabled
      }
      scheduledAccessGrants {
        id
        isOfficeModeEnabled
      }
      externalRef
      type
    }
  }
`;

export const GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS = gql`
  query GetVisitorGroupsForTenantWithAccessGrants($filter: VisitorGroupsFilter) {
    getVisitorGroupsForTenant(filter: $filter) {
      id
      name
      defaultAccessGrants {
        id
        accessSchedule {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
        doors {
          domSerialNumber
          id
          externalLocation {
            id
            name
          }
          manualLocations {
            id
            name
            externalRef
            type
          }
          name
          batteryWarningLevel
          officeMode {
            ... on WeekSchedule {
              weekDays {
                dayName
                from
                to
              }
            }
          }
        }
        locations {
          id
          name
          externalRef
          doors {
            id
            name
            location {
              id
              name
              type
            }
            domSerialNumber
            batteryWarningLevel
          }
          type
        }
      }
      scheduledAccessGrants {
        id
        accessSchedule {
          ... on WeekSchedule {
            weekDays {
              dayName
              from
              to
            }
          }
        }
        doors {
          domSerialNumber
          id
          externalLocation {
            id
            name
          }
          manualLocations {
            id
            name
            externalRef
            type
          }
          name
          batteryWarningLevel
          officeMode {
            ... on WeekSchedule {
              weekDays {
                dayName
                from
                to
              }
            }
          }
        }
        locations {
          id
          name
          externalRef
          doors {
            id
            name
            location {
              id
              name
              type
            }
            domSerialNumber
            batteryWarningLevel
          }
          type
        }
      }
      externalRef
      type
    }
  }
`;

export const GET_TENANT_LEGAL_DOCUMENTS = gql`
  query GetTenantLegalDocuments {
    getTenantLegalDocuments {
      tenant {
        tenantId
        name
      }
      dpa {
        documentType
        currentVersion
        currentText
        id
        acceptedVersion
        acceptedOn
      }
      sla {
        documentType
        currentVersion
        currentText
        id
        acceptedVersion
        acceptedOn
      }
    }
  }
`;

export const GET_TENANT_REGISTRATION_INFO = gql`
  query GetTenantRegistrationInfo {
    getTenantRegistrationInfo {
      tenantId
      azureTenantId
      entraAdminConsentUrl
    }
  }
`;

export const GET_TENANT_NAME = gql`
  query GetTenantName($azureTenantId: String!) {
    getTenantName(azureTenantId: $azureTenantId)
  }
`;
