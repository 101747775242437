import { useReactiveVar } from '@apollo/client';
import Drawer from 'components/Drawer/Drawer';
import Modal from 'components/Modal/Modal';
import useDrawer from 'hooks/useDrawer/useDrawer';
import React, { useState } from 'react';
import { authVar } from 'state/vars';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import MyProfileItem from 'ui/molecules/MyProfileRow/MyProfileItem';
import PageContent from 'ui/templates/PageContent/PageContent';
import useTranslation from 'hooks/useTranslation/useTranslation';
import { createMyProfileTabs } from '../myProfileTabsConst';

const ContentWrapper = styled.div`
  margin: 3rem 0;
  padding-right: 35%;
`;

const AccessApp: React.FC = () => {
  const { translation } = useTranslation();
  const myProfileTabs = createMyProfileTabs(translation);
  const [firstLineTextColor, setFirstLineTextColor] = useState<keyof Colors>('lTextHigh');
  const { showDrawer } = useDrawer();
  const { user } = useReactiveVar(authVar);
  const isMobileDeviceRegistered = user?.mobileDevices?.some(
    (device) => device.role === 'ACCESS' && device.isRegistered
  );
  const [isHover, setIsHover] = useState(false);

  const handleItemClick = () => {
    showDrawer({
      type: 'myProfile'
    });
  };

  const handleOnMouseEnter = () => {
    setFirstLineTextColor('primary');
    setIsHover(true);
  };

  const handleOnMouseLeave = () => {
    setFirstLineTextColor('lTextHigh');
    setIsHover(false);
  };

  return (
    <PageContent title={translation.my_device} titleId="location" tabs={myProfileTabs} id="access-app-page">
      <ContentWrapper onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <MyProfileItem
          firstLineText={translation.atlas_access_application}
          firstLineTextColor={firstLineTextColor}
          secondLineText={
            isMobileDeviceRegistered
              ? `${translation.your_access_profile_is_linked_to_a_mobile_device}`
              : `${translation.your_access_profile_is_currently_not_linked_to_a_mobile_device_scan_the_qr_code_with_your_atlas_access_app_to_link_your_device}`
          }
          iconName={isMobileDeviceRegistered ? 'StatusConnected' : 'StatusDisconnected'}
          isHover={isHover}
          onClick={handleItemClick}
          id="atlas-access-application"
        />
      </ContentWrapper>
      <Drawer />
      <Modal />
    </PageContent>
  );
};
export default AccessApp;
