/* eslint-disable no-magic-numbers */
import React, { useCallback, useEffect, useState } from 'react';
import useDoors from 'hooks/useDoors/useDoors';
import styled from 'styled-components';
import PageContent from 'ui/templates/PageContent/PageContent';
import { useEnqueueSnackbar } from 'hooks/useEnqueueSnackbar';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Icon from 'ui/atoms/Icon/Icon';
import AccessProfilesTooltipInfo from 'ui/organisms/AccessProfilesTooltipInfo/AccessProfilesTooltipInfo';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import useAccessGrants from 'hooks/useAccessGrants/useAccessGrants';
import {
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { GetDoorsForTenantWithOfficeMode_getDoorsForTenant } from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import {
  GetLocationsWithDoorsForTenant_getLocationsForTenant,
  GetLocationsWithDoorsForTenant_getLocationsForTenant_doors
} from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { uniq } from 'lodash';
import useDoorsAndLocations from 'hooks/useDoorsAndLocations/useDoorsAndLocations';
import Search from 'ui/molecules/Search/SearchMUI';
import { DoorsState, LocationsWithDoorsState, VisitorsGroupsWithAccessGrantsState } from 'state/types';
import AccessProfilesLockGroups from 'components/AccessProfilesTables/AccessProfilesLockGroups/AccessProfilesLockGroups';
import AccessProfilesLocks from 'components/AccessProfilesTables/AccessProfilesLocks/AccessProfilesLocks';
import AccessProfilesVisitorGroups from 'components/AccessProfilesTables/AccessProfilesVisitorGroups/AccessProfilesVisitorGroups';
import {
  SecondLineText,
  SecondRowsTextWarning,
  getSecondLineTextWarning
} from 'components/AccessProfilesTables/shared';
import SortingMenuAccessProfilesLockGroups from 'components/SortingMenuAccessProfilesLockGroups/SortingMenuAccessProfilesLockGroups';
import useSortingMenuAccessProfilesLockGroups from 'hooks/useSortingMenuAccessProfilesLockGroups/useSortingMenuAccessProfilesLockGroups';
import useTranslation from 'hooks/useTranslation/useTranslation';
import { createAccessTabs } from '../accessTabsConst';

const IconWrapper = styled.div`
  cursor: pointer;
`;

interface ContentWrapperProps {
  isDefaultPerspective: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;

  > * {
    &:first-child {
      order: ${({ isDefaultPerspective }) => (isDefaultPerspective ? 2 : 0)};
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: ${({ isDefaultPerspective }) => (isDefaultPerspective ? 0 : 2)};
    }
  }
`;

const ContentChildrenWrapper = styled.div`
  flex: 1;
  display: flex;
  height: calc(100vh - 27rem);
  width: 100%;
  overflow-y: auto;
`;

const SearchContainer = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  height: 3.5rem;

  > * {
    &:first-child {
      order: ${({ isDefaultPerspective }) => (isDefaultPerspective ? 2 : 0)};
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: ${({ isDefaultPerspective }) => (isDefaultPerspective ? 0 : 2)};
    }
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const EmptySpace = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;

const SwitchPerspectiveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 0.25rem;
`;

const AccessProfiles = (): JSX.Element => {
  const { translation } = useTranslation();
  const accessTabs = createAccessTabs(translation);

  const [visitorGroupFilterStr, setVisitorGroupFilterStr] = useState('');
  const [locationAndDoorFilterStr, setLocationAndDoorFilterStr] = useState('');
  const [isDefaultPerspective, setIsDefaultPerspective] = useState(true);
  const [isVisitorGroupTableCollapsed, setIsVisitorGroupTableCollapsed] = useState(false);
  const [isLockGroupsTableCollapsed, setIsLockGroupsTableCollapsed] = useState(false);
  const [isLocksTableCollapsed, setIsLocksTableCollapsed] = useState(false);
  const [selectedVisitorGroup, setSelectedVisitorGroup] = useState<
    undefined | GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
  >();
  const [selectedDoor, setSelectedDoor] = useState<undefined | GetDoorsForTenantWithOfficeMode_getDoorsForTenant>();
  const [selectedLocation, setSelectedLocation] = useState<
    undefined | GetLocationsWithDoorsForTenant_getLocationsForTenant
  >();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    visitorsGroupsWithAccessGrants,
    loading: visitorsGroupsWithAccessGrantsLoading,
    assignToAccessGrants,
    assignToAccessGrantsMutationLoading,
    unassignFromAccessGrants,
    unassignFromAccessGrantsMutationLoading
  } = useAccessGrants({
    handleFetchError
  });
  const { doors, loading: doorsLoading } = useDoors({ handleFetchError });
  const { locationsWithDoors, locationsWithDoorsLoading } = useDoorsAndLocations({ handleFetchError });
  const { sortingMenuValue, hideSortingMenu } = useSortingMenuAccessProfilesLockGroups();
  const loadingData = visitorsGroupsWithAccessGrantsLoading || doorsLoading || locationsWithDoorsLoading;
  const loadingMutations = assignToAccessGrantsMutationLoading || unassignFromAccessGrantsMutationLoading;

  const handleSwitchDefaultPerspective = useCallback(() => {
    if (!loadingData)
      if (isDefaultPerspective) {
        setSelectedDoor(undefined);
        setSelectedLocation(undefined);
      } else {
        setSelectedVisitorGroup(undefined);
      }
    if (sortingMenuValue?.isMenuOpen) hideSortingMenu();
    setIsDefaultPerspective(!isDefaultPerspective);
  }, [hideSortingMenu, isDefaultPerspective, loadingData, sortingMenuValue]);

  const handleCollapseVisitorGroupsTable = () => {
    if (selectedVisitorGroup !== undefined) setSelectedVisitorGroup(undefined);
    setIsVisitorGroupTableCollapsed(!isVisitorGroupTableCollapsed);
  };

  const handleCollapseLockGroupsTable = () => {
    if (selectedLocation !== undefined) setSelectedLocation(undefined);
    setIsLockGroupsTableCollapsed(!isLockGroupsTableCollapsed);
    if (sortingMenuValue?.isMenuOpen) hideSortingMenu();
  };

  const handleCollapseLocksTable = () => {
    if (selectedDoor !== undefined) setSelectedDoor(undefined);
    setIsLocksTableCollapsed(!isLocksTableCollapsed);
  };

  const getVisitorGroupAccessGrant = (
    visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
  ) =>
    visitorGroup.defaultAccessGrants.length > visitorGroup.scheduledAccessGrants.length
      ? visitorGroup.defaultAccessGrants[0]
      : visitorGroup.scheduledAccessGrants[0];

  const isVisitorGroupAlreadyAssignToDoor = useCallback(
    (
      visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant,
      door: GetDoorsForTenantWithOfficeMode_getDoorsForTenant
    ) => getVisitorGroupAccessGrant(visitorGroup).doors.some((doorInAccessGrant) => doorInAccessGrant.id === door.id),
    []
  );

  const isVisitorGroupAlreadyAssignToLocation = useCallback(
    (
      visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant,
      location: GetLocationsWithDoorsForTenant_getLocationsForTenant
    ) =>
      getVisitorGroupAccessGrant(visitorGroup).locations.some(
        (locationInAccessGrant) => locationInAccessGrant.id === location.id
      ),
    []
  );

  const getSelectedVisitorGroups = useCallback(() => {
    if (loadingData) {
      return [];
    }

    if (isDefaultPerspective && selectedVisitorGroup) {
      return [selectedVisitorGroup];
    }

    if (selectedDoor) {
      const filterByDoor = (visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) =>
        getVisitorGroupAccessGrant(visitorGroup).doors.some(
          (doorInAccessGrant) => doorInAccessGrant.id === selectedDoor.id
        );

      const selectedVisitorGroupsInLocations = visitorsGroupsWithAccessGrants.filter((visitorGroup) =>
        getVisitorGroupAccessGrant(visitorGroup).locations.some((location) =>
          location.doors.some((doorInAccessGrant) => doorInAccessGrant.id === selectedDoor.id)
        )
      );

      const selectedVisitorGroupsInDoors = visitorsGroupsWithAccessGrants.filter(filterByDoor);

      return [...selectedVisitorGroupsInLocations, ...selectedVisitorGroupsInDoors];
    }

    if (selectedLocation) {
      return visitorsGroupsWithAccessGrants.filter((visitorGroup) =>
        getVisitorGroupAccessGrant(visitorGroup).locations.some(
          (locationInAccessGrant) => locationInAccessGrant.id === selectedLocation.id
        )
      );
    }

    return [];
  }, [
    isDefaultPerspective,
    loadingData,
    selectedDoor,
    selectedLocation,
    selectedVisitorGroup,
    visitorsGroupsWithAccessGrants
  ]);

  const getSelectedVisitorGroupsIds = useCallback(
    () => getSelectedVisitorGroups().map((visitorGroup) => visitorGroup.id),
    [getSelectedVisitorGroups]
  );

  const getSelectedDoors = useCallback(() => {
    if (!loadingData)
      if (isDefaultPerspective && selectedVisitorGroup) {
        const visitorGroupAccessGrant = getVisitorGroupAccessGrant(selectedVisitorGroup);
        const selectedDoorsFromLocations = locationsWithDoors.flatMap((location) =>
          visitorGroupAccessGrant.locations.some((locationInAccessGrant) => locationInAccessGrant.id === location.id)
            ? location.doors.map((door) => door)
            : []
        );
        const selectedDoors = doors.filter((door) =>
          visitorGroupAccessGrant.doors.find((doorInAccessGrant) => doorInAccessGrant.id === door.id)
        );
        return [
          ...selectedDoorsFromLocations,
          ...selectedDoors
        ] as GetLocationsWithDoorsForTenant_getLocationsForTenant_doors[];
      } else {
        if (selectedDoor) {
          return [selectedDoor];
        }
        if (selectedLocation) {
          return [];
        }
      }
    return [];
  }, [
    doors,
    isDefaultPerspective,
    loadingData,
    locationsWithDoors,
    selectedDoor,
    selectedLocation,
    selectedVisitorGroup
  ]);

  const getSelectedDoorsIds = useCallback(() => getSelectedDoors().map((door) => door.id), [getSelectedDoors]);

  const getSelectedLocations = useCallback(() => {
    if (!loadingData)
      if (isDefaultPerspective && selectedVisitorGroup) {
        return locationsWithDoors.flatMap((location) =>
          getVisitorGroupAccessGrant(selectedVisitorGroup)
            .locations.filter((locationsInAccessGrant) => locationsInAccessGrant.id === location.id)
            .map(() => location)
        );
      } else {
        if (selectedDoor) {
          return [];
        }
        if (selectedLocation) {
          return [selectedLocation];
        }
      }
    return [];
  }, [isDefaultPerspective, loadingData, locationsWithDoors, selectedDoor, selectedLocation, selectedVisitorGroup]);

  const getSelectedLocationsIds = useCallback(
    () => getSelectedLocations().map((location) => location.id),
    [getSelectedLocations]
  );

  const getDisabledDoors = useCallback(() => {
    if (!loadingData && isDefaultPerspective && selectedVisitorGroup) {
      const selectedLocations = locationsWithDoors.filter((location) =>
        getVisitorGroupAccessGrant(selectedVisitorGroup).locations.some(
          (locationsInAccessGrant) => locationsInAccessGrant.id === location.id
        )
      );
      const disabledDoors = selectedLocations.flatMap((location) => location.doors);
      return uniq(disabledDoors.map((door) => door.id));
    }

    return [];
  }, [isDefaultPerspective, loadingData, locationsWithDoors, selectedVisitorGroup]);

  const isDoorInLocation = (
    doorId: string,
    location: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations
  ) => location.doors.some((doorInLocation) => doorInLocation.id === doorId);

  const filterLocationsBySelected = (
    locations: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations[],
    selectedLocationIds: string[]
  ) => locations.filter((location) => selectedLocationIds.includes(location.id));

  const filterSelectedLocationsByDisabledDoorId = (
    selectedLocations: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations[],
    disabledDoorId: string
  ) => selectedLocations.filter((location) => location.doors.map((door) => door.id).includes(disabledDoorId));

  const mapLocationsWithDoors = useCallback(
    (
      disabledDoorId: string,
      accessGrant: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants,
      filteredSelectedLocations: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations[]
    ) =>
      uniq(
        accessGrant.locations
          .filter((locationInAccessGrant) => isDoorInLocation(disabledDoorId, locationInAccessGrant))
          .flatMap(() => filteredSelectedLocations)
      ),
    []
  );

  const getDisabledDoorsLocationList = useCallback(() => {
    if (loadingData || !isDefaultPerspective || !selectedVisitorGroup) {
      return [];
    }
    const disabledDoors = getDisabledDoors();
    const selectedLocations = getSelectedLocationsIds();

    const locationListOfDisabledDoors = disabledDoors.map((disabledDoorId) => {
      const filteredSelectedLocations = filterLocationsBySelected(locationsWithDoors, selectedLocations);
      const filteredSelectedLocationsByDisabledDoorId = filterSelectedLocationsByDisabledDoorId(
        filteredSelectedLocations,
        disabledDoorId
      );
      const accessGrant = getVisitorGroupAccessGrant(selectedVisitorGroup);
      return {
        id: disabledDoorId,
        locationList: mapLocationsWithDoors(disabledDoorId, accessGrant, filteredSelectedLocationsByDisabledDoorId)
      };
    });
    return locationListOfDisabledDoors;
  }, [
    getDisabledDoors,
    getSelectedLocationsIds,
    isDefaultPerspective,
    loadingData,
    locationsWithDoors,
    mapLocationsWithDoors,
    selectedVisitorGroup
  ]);

  const filterBySelectedDoor = useCallback(
    (group: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) =>
      getVisitorGroupAccessGrant(group).locations.some((location) =>
        location.doors.some((door) => door.id === selectedDoor?.id)
      ),
    [selectedDoor?.id]
  );

  const getSecondLineRowTextUtil = (
    locationList: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants_locations[]
  ) =>
    `${translation.active_in} ${
      locationList.length > 2
        ? `${translation.multiple_groups}`
        : locationList.map((location) => `${location.name}`).join(` ${translation.and} `)
    }`;

  const getDisabledVisitorGroups = useCallback(() => {
    if (loadingData || isDefaultPerspective || !selectedDoor) {
      return [];
    }

    const disabledVisitorGroups = visitorsGroupsWithAccessGrants.filter(filterBySelectedDoor);

    return disabledVisitorGroups;
  }, [filterBySelectedDoor, isDefaultPerspective, loadingData, selectedDoor, visitorsGroupsWithAccessGrants]);

  const getDisabledVisitorGroupsIdList = () => getDisabledVisitorGroups().map((visitorGroup) => visitorGroup.id);

  const getAdditionalTextVisitorGroups = (): SecondLineText[] =>
    getDisabledVisitorGroups().map((visitorGroup) => ({
      id: visitorGroup.id,
      text:
        getSecondLineRowTextUtil(
          getVisitorGroupAccessGrant(visitorGroup).locations.filter((location) =>
            location.doors.some((door) => door.id === selectedDoor?.id)
          )
        ) || ''
    }));

  const getAdditionalTextLocks = (): SecondLineText[] =>
    getDisabledDoors().map((lock) => ({
      id: lock,
      text:
        getSecondLineRowTextUtil(
          getDisabledDoorsLocationList().flatMap((disabledLocation) => disabledLocation.locationList)
        ) || ''
    }));

  const handleSelectVisitorGroup = useCallback(
    (group: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) => {
      if (!loadingData)
        if (isDefaultPerspective) {
          setSelectedVisitorGroup(group);
        } else {
          if (!loadingMutations && selectedDoor) {
            if (isVisitorGroupAlreadyAssignToDoor(group, selectedDoor))
              unassignFromAccessGrants([getVisitorGroupAccessGrant(group).id], [selectedDoor.id], []);
            else assignToAccessGrants([getVisitorGroupAccessGrant(group).id], [selectedDoor.id], []);
          }
          if (!loadingMutations && selectedLocation) {
            if (isVisitorGroupAlreadyAssignToLocation(group, selectedLocation))
              unassignFromAccessGrants([getVisitorGroupAccessGrant(group).id], [], [selectedLocation.id]);
            else assignToAccessGrants([getVisitorGroupAccessGrant(group).id], [], [selectedLocation.id]);
          }
        }
    },
    [
      assignToAccessGrants,
      isDefaultPerspective,
      isVisitorGroupAlreadyAssignToDoor,
      isVisitorGroupAlreadyAssignToLocation,
      loadingData,
      loadingMutations,
      selectedDoor,
      selectedLocation,
      unassignFromAccessGrants
    ]
  );

  const handleSelectLocation = useCallback(
    (location: GetLocationsWithDoorsForTenant_getLocationsForTenant) => {
      if (!loadingData)
        if (!isDefaultPerspective) {
          setSelectedLocation(location);
          setSelectedDoor(undefined);
        } else if (!loadingMutations && selectedVisitorGroup) {
          if (isVisitorGroupAlreadyAssignToLocation(selectedVisitorGroup, location))
            unassignFromAccessGrants([getVisitorGroupAccessGrant(selectedVisitorGroup).id], [], [location.id]);
          else assignToAccessGrants([getVisitorGroupAccessGrant(selectedVisitorGroup).id], [], [location.id]);
        }
      if (sortingMenuValue?.isMenuOpen) hideSortingMenu();
    },
    [
      assignToAccessGrants,
      hideSortingMenu,
      isDefaultPerspective,
      isVisitorGroupAlreadyAssignToLocation,
      loadingData,
      loadingMutations,
      selectedVisitorGroup,
      sortingMenuValue?.isMenuOpen,
      unassignFromAccessGrants
    ]
  );

  const handleSelectDoor = useCallback(
    (door: GetDoorsForTenantWithOfficeMode_getDoorsForTenant) => {
      if (!loadingData)
        if (!isDefaultPerspective) {
          setSelectedLocation(undefined);
          setSelectedDoor(door);
        } else if (!loadingMutations && selectedVisitorGroup) {
          if (isVisitorGroupAlreadyAssignToDoor(selectedVisitorGroup, door))
            unassignFromAccessGrants([getVisitorGroupAccessGrant(selectedVisitorGroup).id], [door.id], []);
          else assignToAccessGrants([getVisitorGroupAccessGrant(selectedVisitorGroup).id], [door.id], []);
        }
      if (sortingMenuValue?.isMenuOpen) hideSortingMenu();
    },
    [
      assignToAccessGrants,
      hideSortingMenu,
      isDefaultPerspective,
      isVisitorGroupAlreadyAssignToDoor,
      loadingData,
      loadingMutations,
      selectedVisitorGroup,
      sortingMenuValue?.isMenuOpen,
      unassignFromAccessGrants
    ]
  );

  const getFilterData = useCallback(
    <
      T extends
        | GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
        | GetLocationsWithDoorsForTenant_getLocationsForTenant
        | GetDoorsForTenantWithOfficeMode_getDoorsForTenant
    >(
      data: Array<T>,
      filterStr: string
    ): Array<T> => {
      if (filterStr !== '')
        return data.filter((element) => element.name.toLowerCase().includes(filterStr.toLowerCase()));
      return data;
    },
    []
  );

  const getSecondLineTextForDoors = () =>
    doors
      .map((door) => getSecondLineTextWarning(translation, 'Door', door, visitorsGroupsWithAccessGrants))
      .filter((secondLineTextItem) => secondLineTextItem !== undefined) as unknown as SecondRowsTextWarning[];

  const getSecondLineTextForLocations = () =>
    locationsWithDoors
      .map((location) => getSecondLineTextWarning(translation, 'LockGroup', location, visitorsGroupsWithAccessGrants))
      .filter((secondLineTextItem) => secondLineTextItem !== undefined) as unknown as SecondRowsTextWarning[];

  const getSecondLineTextForVisitorGroups = () =>
    visitorsGroupsWithAccessGrants
      .map((visitorGroup) => getSecondLineTextWarning(translation, 'VisitorGroup', visitorGroup))
      .filter((secondLineTextItem) => secondLineTextItem !== undefined) as unknown as SecondRowsTextWarning[];

  useEffect(() => {
    if (
      visitorGroupFilterStr &&
      selectedVisitorGroup &&
      getFilterData(visitorsGroupsWithAccessGrants, visitorGroupFilterStr).find(
        (item) => item === selectedVisitorGroup
      ) === undefined
    )
      setSelectedVisitorGroup(undefined);
  }, [getFilterData, selectedVisitorGroup, visitorGroupFilterStr, visitorsGroupsWithAccessGrants]);

  useEffect(() => {
    if (
      locationAndDoorFilterStr &&
      selectedDoor &&
      getFilterData(doors, locationAndDoorFilterStr).find((item) => item === selectedDoor) === undefined
    )
      setSelectedDoor(undefined);
  }, [doors, getFilterData, locationAndDoorFilterStr, selectedDoor]);

  useEffect(() => {
    if (
      locationAndDoorFilterStr &&
      selectedLocation &&
      getFilterData(locationsWithDoors, locationAndDoorFilterStr).find((item) => item === selectedLocation) ===
        undefined
    )
      setSelectedLocation(undefined);
  }, [getFilterData, locationAndDoorFilterStr, locationsWithDoors, selectedLocation]);

  useEffect(() => {
    setSelectedVisitorGroup(undefined);
    setSelectedLocation(undefined);
    setSelectedDoor(undefined);
  }, [isDefaultPerspective]);

  // refresh data after mutations
  useEffect(() => {
    if (selectedVisitorGroup) {
      setSelectedVisitorGroup(visitorsGroupsWithAccessGrants.find((group) => selectedVisitorGroup.id === group.id));
    }
  }, [selectedVisitorGroup, visitorsGroupsWithAccessGrants]);

  return (
    <PageContent title="ATLAS" titleId="location" tabs={accessTabs} id="access-profiles-page">
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <Tooltip elementOnHover={<AccessProfilesTooltipInfo />} id="access-profiles-tooltip">
            <IconWrapper>
              <Icon name="Info" id="access-profiles-i-icon" />
            </IconWrapper>
          </Tooltip>
        </ComponentWrapper>
      </StickyToTop>
      <ComponentWrapper flexDirection="column" width="100%">
        <SearchContainer isDefaultPerspective={isDefaultPerspective}>
          <SearchWrapper>
            <Search
              onChange={(event) => setLocationAndDoorFilterStr(event.target.value)}
              onFocus={() => hideSortingMenu()}
              placeholder={translation.search_locks_or_lock_groups}
              value={locationAndDoorFilterStr}
              id="search-locks-or-locks-groups"
            />
          </SearchWrapper>
          <SwitchPerspectiveButtonWrapper onClick={handleSwitchDefaultPerspective} id="switch-perspective-button">
            <Icon name="SwitchArrow" height={24} width={24} />
          </SwitchPerspectiveButtonWrapper>
          <SearchWrapper>
            <Search
              onChange={(event) => setVisitorGroupFilterStr(event.target.value)}
              onFocus={() => hideSortingMenu()}
              placeholder={translation.search_user_groups}
              value={visitorGroupFilterStr}
              id="search-visitor-groups"
            />
          </SearchWrapper>
        </SearchContainer>
        <ContentWrapper isDefaultPerspective={isDefaultPerspective}>
          <ContentChildrenWrapper>
            <ComponentWrapper flex="1" flexDirection="column" width="100%" justifyContent="flex-start" gap="1rem">
              {getFilterData(locationsWithDoors, locationAndDoorFilterStr).length > 0 && (
                <AccessProfilesLockGroups
                  id="locks-groups"
                  loading={locationsWithDoorsLoading}
                  disabled={isDefaultPerspective ? Boolean(selectedVisitorGroup === undefined) : false}
                  isCollapsed={isLockGroupsTableCollapsed}
                  handleCollapse={handleCollapseLockGroupsTable}
                  variant={isDefaultPerspective ? 'switch' : 'icon'}
                  lockGroups={locationsWithDoors}
                  filteredLockGroups={
                    getFilterData(locationsWithDoors, locationAndDoorFilterStr) as LocationsWithDoorsState
                  }
                  selectedIds={getSelectedLocationsIds()}
                  disabledIds={[]}
                  additionalTexts={[]}
                  warningTexts={getSecondLineTextForLocations()}
                  handleClickTableRow={handleSelectLocation}
                  doors={getFilterData(doors, locationAndDoorFilterStr) as DoorsState}
                  selectedDoorsIds={getSelectedDoorsIds()}
                />
              )}
              {getFilterData(doors, locationAndDoorFilterStr).length > 0 && (
                <AccessProfilesLocks
                  id="doors"
                  loading={doorsLoading}
                  disabled={isDefaultPerspective ? Boolean(selectedVisitorGroup === undefined) : false}
                  isCollapsed={isLocksTableCollapsed}
                  handleCollapse={handleCollapseLocksTable}
                  isSortingMenuVisible={getFilterData(locationsWithDoors, locationAndDoorFilterStr).length === 0}
                  variant={isDefaultPerspective ? 'switch' : 'icon'}
                  locks={doors}
                  filteredLocks={getFilterData(doors, locationAndDoorFilterStr) as DoorsState}
                  selectedIds={getSelectedDoorsIds()}
                  disabledIds={getDisabledDoors()}
                  additionalTexts={getAdditionalTextLocks()}
                  warningTexts={getSecondLineTextForDoors()}
                  handleClickTableRow={handleSelectDoor}
                />
              )}
            </ComponentWrapper>
          </ContentChildrenWrapper>
          <EmptySpace />
          <ContentChildrenWrapper>
            {getFilterData(visitorsGroupsWithAccessGrants, visitorGroupFilterStr).length > 0 && (
              <AccessProfilesVisitorGroups
                id="user-groups"
                loading={visitorsGroupsWithAccessGrantsLoading}
                disabled={
                  isDefaultPerspective
                    ? false
                    : Boolean(selectedLocation === undefined) && Boolean(selectedDoor === undefined)
                }
                isCollapsed={isVisitorGroupTableCollapsed}
                handleCollapse={handleCollapseVisitorGroupsTable}
                variant={isDefaultPerspective ? 'icon' : 'switch'}
                filteredVisitorGroups={
                  getFilterData(
                    visitorsGroupsWithAccessGrants,
                    visitorGroupFilterStr
                  ) as VisitorsGroupsWithAccessGrantsState
                }
                visitorGroups={visitorsGroupsWithAccessGrants}
                selectedIds={getSelectedVisitorGroupsIds()}
                disabledIds={getDisabledVisitorGroupsIdList()}
                additionalTexts={getAdditionalTextVisitorGroups()}
                warningTexts={getSecondLineTextForVisitorGroups()}
                handleClickTableRow={handleSelectVisitorGroup}
              />
            )}
          </ContentChildrenWrapper>
        </ContentWrapper>
      </ComponentWrapper>
      <SortingMenuAccessProfilesLockGroups />
    </PageContent>
  );
};

export default AccessProfiles;
