import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useTenantLegalDocuments from 'hooks/useTenantLegalDocuments/useTenantLegalDocuments';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InvalidOTR from 'ui/templates/InvalidOTR/InvalidOTR';
import RulesPage from 'ui/templates/RulesPage/RulesPage';
import WelcomePage from 'ui/templates/WelcomePage/WelcomePage';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { tenantsServiceTokenVar } from 'state/vars';
import Loader from 'ui/atoms/Loader/Loader';
import ConnectEntraID from 'ui/templates/ConnectEntraID/ConnectEntraID';
import useTenantRegistrationInfo from 'hooks/useTenantRegistrationInfo/useTenantRegistrationInfo';
import { ThemeProvider } from '@material-ui/core';
import { lightMuiTheme } from 'theme/theme';

const LicensePortalPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get('id');
  tenantsServiceTokenVar(jwt);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    tenantLegalDocuments,
    loading: tenantLegalDocumentsLoading,
    acceptedLegalDocuments,
    acceptedLegalDocumentsLoading
  } = useTenantLegalDocuments({
    handleFetchError
  });
  const { tenantRegistrationInfo, loading: tenantRegistrationInfoLoading } = useTenantRegistrationInfo({
    handleFetchError
  });
  const [isOTRValid, setIsOTRValid] = useState(false);
  const [isProcessStarted, setIsProcessStarted] = useState(false);
  const [isSLACheckBoxSelected, setIsSLACheckBoxSelected] = useState(false);
  const [isSLARulesAccepted, setIsSLARulesAccepted] = useState(false);
  const [isPDACheckBoxSelected, setIsPDACheckBoxSelected] = useState(false);

  const handleSLACheckBoxClick = () => {
    setIsSLACheckBoxSelected(!isSLACheckBoxSelected);
  };

  const handleSLAButtonOnClick = () => {
    if (isSLACheckBoxSelected) setIsSLARulesAccepted(true);
  };

  const handleDPACheckBoxClick = () => {
    setIsPDACheckBoxSelected(!isPDACheckBoxSelected);
  };

  const handleDPAButtonOnClick = () => {
    if (!acceptedLegalDocumentsLoading && tenantLegalDocuments) {
      acceptedLegalDocuments({
        dpa: tenantLegalDocuments.dpa.id,
        sla: tenantLegalDocuments.sla.id
      });
    }
  };

  const handleWelcomePageButtonOnClick = () => {
    setIsProcessStarted(true);
  };

  const handleOnConnectButtonClick = () => {
    if (tenantRegistrationInfo?.entraAdminConsentUrl !== undefined && jwt !== null) {
      window.location.href = `${tenantRegistrationInfo.entraAdminConsentUrl}&state=${jwt}`;
    }
  };

  useEffect(() => {
    if (
      !tenantLegalDocumentsLoading &&
      !tenantRegistrationInfoLoading &&
      tenantLegalDocuments &&
      tenantLegalDocuments.dpa.id &&
      tenantLegalDocuments.sla.id &&
      tenantRegistrationInfo &&
      tenantRegistrationInfo.azureTenantId === null
    ) {
      setIsOTRValid(true);
    }
  }, [
    tenantLegalDocuments,
    tenantLegalDocumentsLoading,
    tenantRegistrationInfo,
    tenantRegistrationInfo?.azureTenantId,
    tenantRegistrationInfoLoading
  ]);

  useEffect(() => {
    if (jwt === '' || jwt === undefined || jwt === null) {
      setIsOTRValid(false);
    }
  }, [jwt, tenantRegistrationInfo, tenantRegistrationInfo?.azureTenantId, tenantRegistrationInfoLoading]);

  if (!isProcessStarted)
    return (
      <ThemeProvider theme={lightMuiTheme}>
        <WelcomePage handleButtonOnClick={handleWelcomePageButtonOnClick} />
      </ThemeProvider>
    );
  if (!isOTRValid)
    return (
      <ThemeProvider theme={lightMuiTheme}>
        <InvalidOTR />
      </ThemeProvider>
    );
  if (!tenantRegistrationInfoLoading && !tenantLegalDocumentsLoading && tenantLegalDocuments) {
    if (
      tenantLegalDocuments.sla.acceptedVersion === tenantLegalDocuments.sla.currentVersion &&
      tenantLegalDocuments.dpa.acceptedVersion === tenantLegalDocuments.dpa.currentVersion
    )
      return (
        <ThemeProvider theme={lightMuiTheme}>
          <ConnectEntraID handleOnConnectButtonClick={handleOnConnectButtonClick} />
        </ThemeProvider>
      );
    if (!isSLARulesAccepted)
      return (
        <ThemeProvider theme={lightMuiTheme}>
          <RulesPage
            title="ATLAS Access – Software License Agreement"
            rulesContent={tenantLegalDocuments.sla.currentText}
            rulesName="Software License Agreement (SLA)"
            isCheckBoxChecked={isSLACheckBoxSelected}
            handleCheckBoxOnClick={handleSLACheckBoxClick}
            handleButtonOnClick={handleSLAButtonOnClick}
          />
        </ThemeProvider>
      );
    return (
      <ThemeProvider theme={lightMuiTheme}>
        <RulesPage
          title="ATLAS Access – Data Processing Agreement"
          rulesContent={tenantLegalDocuments.dpa.currentText}
          rulesName="Data Processing Agreement (DPA)"
          isCheckBoxChecked={isPDACheckBoxSelected}
          handleCheckBoxOnClick={handleDPACheckBoxClick}
          handleButtonOnClick={handleDPAButtonOnClick}
        />
      </ThemeProvider>
    );
  }
  return (
    <ComponentWrapper alignItems="center" justifyContent="center" width="100vw" height="100vh">
      <Loader />;
    </ComponentWrapper>
  );
};
export default LicensePortalPage;
