import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'theme/theme';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  clearButtonOnClick?: () => void;
  id?: string;
}

const useStyles = makeStyles({
  TextField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.lTextMedium
      },
      '&:hover fieldset': {
        borderColor: theme.colors.lTextMedium
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.primary
      }
    },
    '& .MuiInputBase-input': {
      color: theme.colors.lTextHigh
    },
    '& .MuiFormLabel-root': {
      color: theme.colors.lTextMedium
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.colors.lTextHigh
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.colors.lTextMedium
    }
  }
});

const Search = React.forwardRef<HTMLInputElement | null, Props>((props) => {
  const { translation } = useTranslation();
  const { onChange, value, clearButtonOnClick, id = 'search' } = props;
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      id={id}
      onChange={onChange}
      value={value}
      variant="outlined"
      size="small"
      placeholder={translation.search}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fill: value !== '' ? theme.colors.lTextHigh : theme.colors.lTextMedium }} />
          </InputAdornment>
        ),
        endAdornment:
          value !== '' && clearButtonOnClick !== undefined ? (
            <InputAdornment position="end">
              <IconButton onClick={clearButtonOnClick} aria-label="clear">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
      }}
      className={classes.TextField}
    />
  );
});

export default Search;
