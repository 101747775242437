import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from 'theme/GlobalStyle';
import Apollo from 'components/Apollo/Apollo';
import ThemeProviderExtended from 'components/ThemeProviderExtended/ThemeProviderExtended';
import SnackbarProvider from 'ui/providers/SnackbarProvider';
import { MsalProvider } from '@azure/msal-react';
import { getMsalInstance } from 'environment/SsoConfig';
import { ThemeProvider } from '@material-ui/core';
import { darkMuiTheme } from 'theme/theme';
import App from './App';

import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';
import '@fontsource/rubik/800.css';
import '@fontsource/rubik/900.css';

ReactDOM.render(
  <MsalProvider instance={getMsalInstance()}>
    <Apollo>
      <ThemeProviderExtended>
        <SnackbarProvider>
          <GlobalStyle />
          <ThemeProvider theme={darkMuiTheme}>
            <App />
          </ThemeProvider>
        </SnackbarProvider>
      </ThemeProviderExtended>
    </Apollo>
  </MsalProvider>,
  document.getElementById('root')
);
