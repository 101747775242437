import React, { useCallback, useEffect, useState } from 'react';
import Search from 'ui/molecules/Search/Search';
import styled from 'styled-components';
import Drawer from 'components/Drawer/Drawer';
import PageContent from 'ui/templates/PageContent/PageContent';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import { PermissionsState } from 'state/types';
import ArrayUtil from 'utils/Array/Array.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import getVisitorGroupIcon from 'utils/VisitorGroupIcon/VisitorGroupIcon.util';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import usePermissions from 'hooks/usePermissions/usePermissions';
import useSortingPermissionsTable from 'hooks/useSorting/useSortingPermissionsTable/useSortingPermissionsTable';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import { kebabCase } from 'lodash';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { createPermissionsTabs } from '../permissionsTabsConst';

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

export const createHeaderLeftTable = (translation: Translation) => [translation.ad_groups];
export const createHeaderRightTable = (translation: Translation) => [translation.permissions];

const Permissions = (): JSX.Element => {
  const { translation } = useTranslation();
  const permissionsTabs = createPermissionsTabs(translation);
  const headerLeftTable = createHeaderLeftTable(translation);
  const headerRightTable = createHeaderRightTable(translation);
  const [filterStr, setFilterStr] = useState('');
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const skeletonArray = ArrayUtil.SkeletonArray();
  const {
    groupsWithPermissions,
    loading: visitorGroupsLoading,
    setIsAdminGroup,
    setIsAdminGroupLoading
  } = usePermissions({
    handleFetchError
  });
  const [leftTableSelectedRowExternalRef, setLeftTableSelectedRowExternalRef] = useState<string>();
  const { handleSortingIcon, handleSortingOnClick } = useSortingPermissionsTable(translation);

  const getFilterData = useCallback((): PermissionsState => {
    if (filterStr !== '')
      return groupsWithPermissions.filter((group) => group.name.toLowerCase().includes(filterStr.toLowerCase()));
    return groupsWithPermissions;
  }, [filterStr, groupsWithPermissions]);

  const handleLeftTableOnRowClick = (externalRef: string) => {
    if (leftTableSelectedRowExternalRef !== externalRef) setLeftTableSelectedRowExternalRef(externalRef);
    else setLeftTableSelectedRowExternalRef(undefined);
  };

  const IsGroupAdmin = (externalRef: string | undefined) =>
    groupsWithPermissions.find((group) => group.externalRef === externalRef)?.isAdminGroup;

  const handleRightTableOnRowClick = () => {
    if (leftTableSelectedRowExternalRef !== undefined && !setIsAdminGroupLoading) {
      setIsAdminGroup(leftTableSelectedRowExternalRef, !IsGroupAdmin(leftTableSelectedRowExternalRef));
    }
  };

  useEffect(() => {
    if (
      filterStr &&
      leftTableSelectedRowExternalRef &&
      getFilterData().find((item) => item.externalRef === leftTableSelectedRowExternalRef) === undefined
    )
      setLeftTableSelectedRowExternalRef(undefined);
  }, [filterStr, getFilterData, leftTableSelectedRowExternalRef]);

  return (
    <PageContent title="ATLAS" titleId="location" tabs={permissionsTabs} id="permissions-page">
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <Search onChange={(event) => setFilterStr(event.target.value)} value={filterStr} />
          </SearchWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <ComponentWrapper width="100%" height="calc(100vh - 24rem)" margin="0 1rem 0 0" gap="1rem">
        <Table
          header={
            <TableHeaderRow placeForAvatarOrSwitchOrCheckbox placeForArrow>
              <TableHeader
                headerText={headerLeftTable[0]}
                id={kebabCase(`header-${headerLeftTable[0]}`)}
                flex="0 0 100%"
                iconSorting={handleSortingIcon(headerLeftTable[0])}
                onClick={() => handleSortingOnClick(headerLeftTable[0])}
              />
            </TableHeaderRow>
          }
          headerId="ad-groups-header"
          rowsWrapperId="ad-groups-list"
        >
          {visitorGroupsLoading
            ? skeletonArray.map((_, index) => (
                <TableRow
                  beforeSwitchSkeleton
                  id={`row-skeleton-${index}`}
                  marginAfterContent
                  key={`skeletonTableRowLeftColumn-${_.id}`}
                >
                  <TableCell isLoading firstLineText="" flex="0 0 100%" />
                </TableRow>
              ))
            : getFilterData().map((userGroup, id) => (
                <TableRow
                  hoverEffect
                  beforeContentIconProps={{
                    name: getVisitorGroupIcon.getVisitorGroupIcon('AZURE_AD'),
                    width: 24,
                    height: 24,
                    viewBox: '0 0 32 32',
                    color: 'transparent',
                    stroke: 'lTextHigh'
                  }}
                  id={`row-${id}`}
                  key={userGroup.id}
                  onClick={() => handleLeftTableOnRowClick(userGroup.externalRef)}
                  selected={userGroup.externalRef === leftTableSelectedRowExternalRef}
                >
                  <TableCell firstLineText={userGroup.name} firstLineId={`row-${id}-name`} flex="0 0 100%" />
                </TableRow>
              ))}
        </Table>
        <Table
          header={
            <TableHeaderRow>
              <TableHeader
                headerText={headerRightTable[0]}
                id={`header-${headerRightTable[0].toLocaleLowerCase()}`}
                flex="0 0 100%"
              />
            </TableHeaderRow>
          }
          headerId="ad-groups-header"
          rowsWrapperId="ad-groups-list"
        >
          {visitorGroupsLoading ? (
            skeletonArray.map((_, index) => (
              <TableRow id={`row-skeleton-${index}`} marginAfterContent key={`skeletonTableRowRightColumn-${_.id}`}>
                <TableCell isLoading firstLineText="" flex="0 0 100%" />
              </TableRow>
            ))
          ) : (
            <TableRow
              id="row-permission"
              key="row-permission"
              afterSwitchProps={{
                checked: IsGroupAdmin(leftTableSelectedRowExternalRef) || false,
                disabled: leftTableSelectedRowExternalRef === undefined
              }}
              onClick={handleRightTableOnRowClick}
              marginAfterContent
              disabled={leftTableSelectedRowExternalRef === undefined}
            >
              <TableCell
                firstLineText={translation.is_atlas_admin}
                firstLineId="row-permission-firstLine"
                secondLineText={translation.allows_users_in_this_group_to_configure_the_system_as_an_admin}
                secondLineId="row-permission-second-line"
                flex="0 0 100%"
              />
            </TableRow>
          )}
        </Table>
      </ComponentWrapper>
      <Drawer />
    </PageContent>
  );
};

export default Permissions;
