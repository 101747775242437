/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { inputLabelClasses } from "@mui/material/InputLabel";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, Theme as MuiTheme } from "@mui/material/styles";


/**
 * Light and dark theme definition
 */
const lightTheme = {
  colors: {
    primary: {
      main: "#CCCC00",
      dark: "#A99F00",
      light: "#E0E341",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#63666F",
      dark: "#37393F",
      light: "#898E9B",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
      contrastText: "#FFFFFF"
    }
  },
  backgroundColors: {
    background1: "#F5F5F5",
    background2: "#FAFAFA",
    background3: "#FFFFFF",
    focusVisible: "#CCCC004D",
    skeleton: "rgba(13, 13, 13, 0.10)",
    disabled: "rgba(0, 0, 0, 0.15)"
  },
  borderColors: {
    main: "#E6E6E6",
    hover: "#C6C6C6",
    focused: "#CCCC00"
  },
  fontColors: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.60)",
    disabled: "rgba(0, 0, 0, 0.38)"
  }
};

const darkTheme = {
  colors: {
    primary: lightTheme.colors.primary,
    secondary: {
      main: "#9c9990",
      dark: "#c8c6c0",
      light: "#767164",
      contrastText: "#000000"
    },
    error: lightTheme.colors.error,
    warning: lightTheme.colors.warning,
    info: lightTheme.colors.info,
    success: lightTheme.colors.success
  },
  backgroundColors: {
    background1: "#101010",
    background2: "#121212",
    background3: "#181818",
    focusVisible: "#CCCC004D",
    skeleton: "rgba(242, 242, 242, 0.10)",
    disabled: "rgba(0, 0, 0, 0.15)"
  },
  borderColors: {
    main: "#1F1F1F",
    hover: "#717171",
    focused: "#CCCC00"
  },
  fontColors: {
    primary: "rgba(255, 255, 255, 0.87)",
    secondary: "rgba(255, 255, 255, 0.60)",
    disabled: "rgba(255, 255, 255, 0.38)"
  }
};

/**
 * Mui theme definition
 */
export const createMuiTheme = (mode: "dark" | "light"): MuiTheme => {
  const theme = mode === "dark" ? darkTheme : lightTheme;
  return createTheme({
    palette: {
      mode,
      text: {
        primary: theme.fontColors.primary,
        secondary: theme.fontColors.secondary,
        disabled: theme.fontColors.disabled
      },
      primary: {
        main: theme.colors.primary.main,
        dark: theme.colors.primary.dark,
        light: theme.colors.primary.light,
        contrastText: theme.colors.primary.contrastText
      },
      secondary: {
        main: theme.colors.secondary.main,
        dark: theme.colors.secondary.dark,
        light: theme.colors.secondary.light,
        contrastText: theme.colors.secondary.contrastText
      },
      error: {
        main: theme.colors.error.main,
        dark: theme.colors.error.dark,
        light: theme.colors.error.light,
        contrastText: theme.colors.error.contrastText
      },
      warning: {
        main: theme.colors.warning.main,
        dark: theme.colors.warning.dark,
        light: theme.colors.warning.light,
        contrastText: theme.colors.warning.contrastText
      },
      info: {
        main: theme.colors.info.main,
        dark: theme.colors.info.dark,
        light: theme.colors.info.light,
        contrastText: theme.colors.info.contrastText
      },
      success: {
        main: theme.colors.success.main,
        dark: theme.colors.success.dark,
        light: theme.colors.success.light,
        contrastText: theme.colors.success.contrastText
      }
    },
    typography: {
      fontFamily: "Rubik",
      h1: {
        fontSize: "96px",
        fontWeight: 300,
        lineHeight: "116.7%",
        letterSpacing: "-1.5px"
      },
      h2: {
        fontSize: "60px",
        fontWeight: 300,
        lineHeight: "120%",
        letterSpacing: "-0.5px"
      },
      h3: {
        fontSsize: "48px",
        lineHeight: "116.7%"
      },
      h4: {
        fontSize: "34px",
        lineHeight: "123.5%",
        letterSpacing: "0.25px"
      },
      h5: {
        fontSize: "24px",
        lineHeight: "133.4%"
      },
      h6: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "160%,",
        letterSpacing: "0.15px"
      },
      subtitle1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "175%",
        letterSpacing: "0.15px"
      },
      subtitle2: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "157%",
        letterSpacing: "0.1px"
      },
      body1: {
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.15px"
      },
      body2: {
        fontSize: "14px",
        lineHeight: "143%",
        letterWpacing: "0.17px"
      },
      caption: {
        fontSize: "12px",
        lineHeight: "166%",
        letterSpacing: "0.4px"
      },
      overline: {
        fontSize: "12px",
        lineHeight: "266%",
        letterSpacing: "1px",
        textTransform: "uppercase"
      },
      button: {
        fontWeight: 500
      },
      allVariants: {
        fontStyle: "normal",
        fontWeight: 400
      }
    },
    shape: {
      borderRadius: 4
    },
    custom: {
      backgroundColors: theme.backgroundColors,
      borderColors: theme.borderColors,
      fontColors: theme.fontColors,
      borderWidth: 1,
      borderRadius: 4
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          // @ts-ignore
          root: {
            fontWeight: "400"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          // @ts-ignore
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === "icon" && {
              textTransform: "none",
              border: `solid 1px ${theme?.custom.borderColors.main}`,
              fontWeight: "400",
              backgroundColor: theme?.custom.backgroundColors.background3
            })
          })
        }
      },
      MuiDivider: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme }) => ({
            borderColor: theme?.custom.borderColors.main
          })
        }
      },
      MuiListItem: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme }) => ({
            borderColor: theme?.custom.borderColors.main
          })
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme }) => ({
            borderColor: theme?.custom.borderColors.main
          })
        }
      },
      MuiSelect: {
        styleOverrides: {
          // @ts-ignore
          select: ({ theme }) => ({
            backgroundColor: theme?.custom.backgroundColors.background3
          })
        }
      },
      MuiTableCell: {
        styleOverrides: {
          // @ts-ignore
          root: ({ theme }) => ({
            borderColor: theme?.custom.borderColors.main
          })
        }
      },
      MuiTextField: {
        styleOverrides: {
          // @ts-ignore
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === "outlined" && {
              "& .MuiInputBase-root": {
                backgroundColor: theme?.custom.backgroundColors.background3
              },
              // notched outline
              [`& .${outlinedInputClasses.root}:not(.Mui-error):not(.Mui-focused) .${outlinedInputClasses.notchedOutline}`]:
                {
                  borderColor: theme?.custom.borderColors.main
                },
              [`&:hover .${outlinedInputClasses.root}:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused) .${outlinedInputClasses.notchedOutline}`]:
                {
                  borderColor: theme?.custom.borderColors.hover
                },
              "&.info": {
                // notched border
                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: `${theme?.palette.info.main} !important`
                },
                // label
                [`& .${inputLabelClasses.outlined}`]: {
                  color: `${theme?.palette.info.main} !important`
                }
              },
              "&.warning": {
                // notched border
                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: `${theme?.palette.warning.main} !important`
                },
                // label
                [`& .${inputLabelClasses.outlined}`]: {
                  color: `${theme?.palette.warning.main} !important`
                }
              },
              "&.success": {
                // notched border
                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: `${theme?.custom.borderColors.main} !important`
                },
                // label
                [`& .${inputLabelClasses.outlined}`]: {
                  color: `${theme?.palette.text.secondary} !important`
                },
                //
                "& .MuiFormHelperText-root": {
                  color: `${theme?.palette.success.main} !important`
                }
              }
            })
          })
        }
      }
    }
  });
};

/**
 * Additional variant
 */
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    icon: true;
  }
}

declare module "@mui/material/styles" {
  interface CustomTheme {
    custom: {
      [key: string]: unknown;
    };
  }
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}
declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}
