import React from 'react';
import styled from 'styled-components';
import Profile, { Props as ProfileProps } from 'ui/molecules/Profile/Profile';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { useNavigate } from 'react-router-dom';
import useTranslation, { arrayOfSupportedLanguages } from 'hooks/useTranslation/useTranslation';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Separator from 'ui/atoms/Separator/Separator';
import Icon from 'ui/atoms/Icon/Icon';
import { colors, darkMuiTheme } from 'theme/theme';
import { Divider, Grow, makeStyles, Paper, Popper, Typography as TypographyMUI } from '@material-ui/core';

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.3125rem 1.5rem;
`;

export interface Props {
  profile: ProfileProps;
  isMenuOpen: boolean;
  isMobileDeviceRegistered: boolean;
  anchorRef: React.RefObject<HTMLDivElement>;
  popperRef: React.RefObject<HTMLDivElement>;
  languageSelectValue: string;
  handleLanguageSelectOnChange: (event: SelectChangeEvent) => void;
  handleOpenMenu: () => void;
  handleMyDevicesOnClick: () => void;
  handleLogoutOnClick: () => void;
}

const TopBar: React.FC<Props> = ({
  profile,
  isMenuOpen,
  isMobileDeviceRegistered,
  anchorRef,
  popperRef,
  handleOpenMenu,
  languageSelectValue,
  handleLanguageSelectOnChange,
  handleMyDevicesOnClick,
  handleLogoutOnClick
}) => {
  const { translation } = useTranslation();
  const navigate = useNavigate();

  const useStyles = makeStyles({
    select: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.primary} !important`
      }
    }
  });
  const classes = useStyles();

  return (
    <Wrapper id="sidebar">
      <ComponentWrapper flex="1" alignSelf="flex-start" gap="0.5rem" alignItems="center" justifyContent="start">
        <ComponentWrapper onClick={() => navigate('/')} id="logo-title" cursor="pointer" gap="0.5rem">
          <Icon name="ArrowRight" width={15} height={24} viewBox="0 0 24 24" color="primary" />{' '}
          <TypographyMUI id="title" variant="h5" color="primary">
            ATLAS
          </TypographyMUI>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper flex="1 0 4rem" justifyContent="end">
        <Divider variant="middle" flexItem orientation="vertical" />
        <Profile
          name={profile.name}
          picture={profile.picture}
          anchorRef={anchorRef}
          onClick={handleOpenMenu}
          hoverEffect={!!handleOpenMenu}
        />
        <Popper
          open={isMenuOpen}
          role={undefined}
          placement="bottom-start"
          ref={popperRef}
          transition
          disablePortal
          style={{ zIndex: 1000, backgroundColor: `${darkMuiTheme.palette.background.paper} !important` }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                position: 'fixed',
                zIndex: 1000,
                top: '3rem',
                right: '2.75rem',
                width: '15rem',
                padding: '0.625rem 1rem',
                transformOrigin: 'right-top',
                backgroundColor: `${darkMuiTheme.palette.background.paper} !important`
              }}
            >
              <Paper
                style={{
                  backgroundColor: `${darkMuiTheme.palette.background.paper} !important`
                }}
              >
                <MenuList id="menu-id" autoFocusItem={isMenuOpen} aria-labelledby="composition-button">
                  <FormControl fullWidth>
                    <ComponentWrapper flexDirection="column" gap="0.25rem">
                      <Typography variant="label" color="lTextHigh" id="" language-select-label>
                        {translation.language}
                      </Typography>
                      <Select
                        MenuProps={{
                          MenuListProps: {
                            style: {
                              backgroundColor: darkMuiTheme.palette.background.paper
                            }
                          }
                        }}
                        id="language-select"
                        value={languageSelectValue}
                        onChange={handleLanguageSelectOnChange}
                        className={classes.select}
                        sx={{
                          backgroundColor: darkMuiTheme.palette.background.paper,
                          color: darkMuiTheme.palette.primary.contrastText
                        }}
                      >
                        {arrayOfSupportedLanguages.map((supportedLanguage) => (
                          <MenuItem
                            style={{
                              color: darkMuiTheme.palette.primary.contrastText,
                              backgroundColor: darkMuiTheme.palette.background.paper
                            }}
                            value={supportedLanguage.value}
                          >
                            {supportedLanguage.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </ComponentWrapper>
                  </FormControl>
                  <Separator marginTop="0.625rem" />
                  <MenuItem
                    style={{ color: darkMuiTheme.palette.primary.contrastText }}
                    onClick={handleMyDevicesOnClick}
                  >
                    <ComponentWrapper gap="1rem" height="1.625rem" margin="0.25rem 0">
                      <Icon
                        viewBox="0 0 26 26"
                        color="lTextHigh"
                        name={isMobileDeviceRegistered ? 'MobileDeviceConnected' : 'MobileDeviceDisconnected'}
                      />
                      {translation.my_device}
                    </ComponentWrapper>
                  </MenuItem>
                  <MenuItem style={{ color: darkMuiTheme.palette.primary.contrastText }} onClick={handleLogoutOnClick}>
                    <ComponentWrapper gap="1rem" height="1.625rem">
                      <Icon name="LogOut" />
                      {translation.logout}
                    </ComponentWrapper>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ComponentWrapper>
    </Wrapper>
  );
};

export default TopBar;
