import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBarUI from 'ui/organisms/TopBar/TopBar';
import { useMsal } from '@azure/msal-react';
import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import useTranslation, { SupportedLanguages } from 'hooks/useTranslation/useTranslation';
import { SelectChangeEvent } from '@mui/material/Select';

const TopBar: React.FC = () => {
  const { savePreferredLanguageInCache, getLanguage } = useTranslation();
  const { instance, accounts } = useMsal();
  const { user } = useReactiveVar(authVar);
  const navigate = useNavigate();

  const isMobileDeviceRegistered = user?.mobileDevices?.some(
    (device) => device.role === 'ACCESS' && device.isRegistered
  );
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [languageSelect, setLanguageSelect] = React.useState<string>();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<HTMLDivElement>(null);

  const handleOpenMenu = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  const handleLanguageSelectOnChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    setLanguageSelect(event.target.value);
    savePreferredLanguageInCache(event.target.value as SupportedLanguages);
  };

  const handleLogout = async () => {
    await instance.logout({ account: accounts[0], postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI });
  };

  const navigateToMyProfile = () => {
    navigate('./my-profile/access-app');
  };

  useEffect(() => {
    if (languageSelect === undefined) setLanguageSelect(getLanguage());
  }, [getLanguage, languageSelect]);

  const handleClickAnywhere = (event: MouseEvent) => {
    // Check if the click is on the Popper
    if (popperRef.current && popperRef.current.contains(event.target as Node)) {
      return; // Do nothing if the click is inside the Popper
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return; // Do nothing if the click is on the profile
    }
    // Close the Popper if the click is outside both
    setIsMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAnywhere);
    return () => {
      document.removeEventListener('mousedown', handleClickAnywhere);
    };
  }, []);

  return (
    <TopBarUI
      profile={{ name: user?.name || '' }}
      isMenuOpen={isMenuOpen}
      anchorRef={anchorRef}
      popperRef={popperRef}
      languageSelectValue={languageSelect || 'en'}
      isMobileDeviceRegistered={Boolean(isMobileDeviceRegistered)}
      handleLanguageSelectOnChange={handleLanguageSelectOnChange}
      handleOpenMenu={handleOpenMenu}
      handleMyDevicesOnClick={navigateToMyProfile}
      handleLogoutOnClick={handleLogout}
    />
  );
};

export default TopBar;
