import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Title from 'ui/atoms/Title/Title';
import { Button, Divider, Typography } from '@material-ui/core';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 16rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 5rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

export interface Props {
  onGoToAtlasWebPortalButtonClick: () => void;
}

const AlmostReady: React.FC<Props> = ({ onGoToAtlasWebPortalButtonClick }) => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title />
    </TitleWrapper>
    <Wrapper>
      <Typography variant="h4">Almost ready</Typography>
      <Divider />
      <Typography variant="body1">
        ATLAS Enterprise apps have been installed and users are being synchronized. Once user groups have been
        synchronized, you are able to login at the ATLAS Web portal. This process can take up to an hour, depending on
        the amount of users that needs to be synced.
      </Typography>
      <Divider />
      <ComponentWrapper alignSelf="center" width="20.5rem">
        <Button fullWidth variant="contained" size="large" color="primary" onClick={onGoToAtlasWebPortalButtonClick}>
          Go to ATLAS web portal
        </Button>
      </ComponentWrapper>
    </Wrapper>
  </>
);
export default AlmostReady;
